import React from "react";
import Container from "../components/chat-channel/Container";
import Layout from "../components/chat-channel/Layout";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import { RequestForm } from "../components/form";
import {GoToPopup} from "./homepage"
import {CookiesPoup} from "./homepage"
export default function TermsAndCondtions() {
  return (
    <>
      <TitleAndMetaTags
        title="Terms and Conditions Acceptance | Workativ"
        description="Please read these Terms of Use carefully. By accessing and using the site, product, services you accept and agree to these Terms of Use."
        keywords={["workativ terms and conditions"]}
        ogTitle="Terms and Conditions Acceptance | Workativ"
        ogDescription="Please read these Terms of Use carefully. By accessing and using the site, product, services you accept and agree to these Terms of Use."
      />
      <Container>
        <Layout logoFor="WORKATIV" product="WORKATIV">
          <section className="terms_condtions">
            <div className="space-explorations">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 space-explorations-content">
                    <h1>TERMS OF USE </h1>
                  </div>
                </div>
              </div>
            </div>
            <TermsContent />
            {/* <RequestForm isFooterForm={true} /> */}
          </section>
          {/* <GoToPopup />
          <CookiesPoup /> */}
        </Layout>
      </Container>
    </>
  );
}

function TermsContent() {
  return (
    <>
      <section className="statistics-boggle privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>1. GENERAL</h3>
              <p>
                The use of our Services is solely governed by these Terms as
                well as the Privacy Policy, and any modifications or amendments
                made thereto by us, from time to time, at our sole discretion.
                If You continue to access and use the Services, you are agreeing
                to comply with and be bound by the following Terms and Our
                Privacy Policy.
              </p>
              <p>
                You unequivocally agree that these Terms and the aforementioned
                Policy constitute a legally binding agreement between Us, and
                you acknowledge and agree that no signature or express act is
                required to make these Terms and the Policy binding on the User
                and that the User’s act of using or accessing the Service shall
                constitute the User’s full and final acceptance of these Terms
                and Policy.{" "}
              </p>
              <div className="alpha_letters">
                <p className="alpha_p">
                  For the purpose of these Terms of Use <b>(“Terms”),</b>{" "}
                  wherever the context so requires,{" "}
                </p>
                <ol type="a">
                  <li>
                    <b>Us, We, Our: </b> means and refers to Workativ, a
                    Delaware corporation, or any of its successors or assignees.{" "}
                  </li>
                  <li>
                    <b>You, Your, Yourself, User: </b> shall mean and refer to
                    natural and legal individuals who use the Application or
                    Website and who is competent to enter into binding
                    contracts.{" "}
                  </li>
                  <li>
                    <b>End-User: </b> means any person or entity other than You,
                    or Your Users with whom You interact using the Service(s).{" "}
                  </li>
                  <li>
                    <b>Account: </b> means any accounts or instances created by
                    or on behalf of You for access and use of any of the
                    Service(s).{" "}
                  </li>
                  <li>
                    <b>Affiliate: </b> means, with respect to a party, any
                    entity that directly or indirectly controls, is controlled
                    by, or is under common control with such party, whereby
                    “control” (including, with correlative meaning, the terms
                    “controlled by” and “under common control”) means the
                    possession, directly or indirectly, of the power to direct,
                    or cause the direction of the management and policies of
                    such Person, whether through the ownership of voting
                    securities, by contract, or otherwise.{" "}
                  </li>
                  <li>
                    <b>API: </b> means the application programming interfaces
                    developed, enabled by or licensed to Us that permits a User
                    to access certain functionality provided by the Service(s).{" "}
                  </li>
                  <li>
                    <b>Applicable Data Protection Law: </b> where You are
                    established in the European Economic Area, means (i) prior
                    to 25th May 2018, the Directive 95/46/EC on the protection
                    of individuals with regard to the Processing of Personal
                    Data and on the free movement of such data and (ii) after
                    25th May 2018, the EU Regulation 2016/679 (General Data
                    Protection Regulation) and any applicable national laws made
                    under it; where You are established in Switzerland, means
                    the Swiss Federal Act of 19 June 1992 on Data Protection (as
                    may be amended or superseded); and where You are established
                    in any other territory, means data protection laws that are
                    applicable in that territory.{" "}
                  </li>
                  <li>
                    <b>Confidential Information: </b> means all information
                    disclosed by You to Us or by Us to You which is in tangible
                    form and labelled “confidential” (or with a similar legend)
                    or which a reasonable person would understand to be
                    confidential given the nature of the information and
                    circumstances of disclosure. For purposes of these Terms,
                    Service Data shall be deemed Confidential Information.
                    Notwithstanding the foregoing, Confidential Information
                    shall not include any information which (a) was publicly
                    known and made generally available in the public domain
                    prior to the time of disclosure by the disclosing party; (b)
                    becomes publicly known and made generally available after
                    disclosure by the disclosing party to the receiving party
                    through no action or inaction of the receiving party; (c) is
                    already in the possession of the receiving party at the time
                    of disclosure by the disclosing party as shown by the
                    receiving party’s files and records prior to the time of
                    disclosure; (d) is obtained by the receiving party from a
                    third party without a breach of such third party’s
                    obligations of confidentiality; (e) is independently
                    developed by the receiving party without use of or reference
                    to the disclosing party’s Confidential Information, as shown
                    by documents and other competent evidence in the receiving
                    party’s possession; or (f) is required by law to be
                    disclosed by the receiving party, provided that the
                    receiving party shall, to the extent legally permitted, give
                    the disclosing party written notice of such requirement
                    prior to disclosing so that the disclosing party may seek a
                    protective order or other appropriate relief.{" "}
                  </li>
                  <li>
                    <b>Documentation: </b> means any written or electronic
                    documentation, images, video, text or sounds specifying the
                    functionalities of the Service(s) provided or made available
                    by Us to You or Your Users through the Service(s) or
                    otherwise.{" "}
                  </li>
                  <li>
                    <b>Form: </b> means any service order form referencing these
                    Terms and executed or approved by You and Us with respect to
                    Your subscription to the Service(s), which form may detail,
                    among other things, the number of Users authorized to use
                    the Service(s) under Your subscription to the Service(s) and
                    the Service Plan(s) applicable to Your subscription to the
                    Service(s).{" "}
                  </li>
                  <li>
                    <b>Marketplace: </b> means an online marketplace for Our
                    Automations that interoperate with Our Service(s).{" "}
                  </li>
                  <li>
                    <b>Personal Data: </b> means data relating to a living
                    individual who is or can be identified either from the data
                    or from the data in conjunction with other information that
                    is in, or is likely to come into, the possession of the data
                    controller (as defined under Applicable Data Protection
                    Law).{" "}
                  </li>
                  <li>
                    <b>Processing/To Process: </b> means any operation or set of
                    operations which is performed upon Personal Data, whether or
                    not by automatic means, such as collection, recording,
                    organization, storage, adaptation or alteration, retrieval,
                    consultation, use, disclosure by transmission, dissemination
                    or otherwise making available, alignment or combination,
                    blocking, erasure or destruction.{" "}
                  </li>
                  <li>
                    <b>Service Data: </b> means all electronic data, text,
                    messages or other materials, including Personal Data of
                    Users and End-Users, submitted to the Service(s) by You
                    through Your Account in connection with Your use of the
                    Service(s), including, without limitation, Personal Data.{" "}
                  </li>
                  <li>
                    <b>Service(s): </b> mean and include Workativ Assistant or
                    any new services that We may introduce as a Service to which
                    You may subscribe to and any updates, modifications or
                    improvements to the Service(s), including individually and
                    collectively, Software, the API and any Documentation. You
                    may subscribe to one or more of the Service(s). They may be
                    subject to separate and distinct Service Plans.{" "}
                  </li>
                  <li>
                    <b>Service Plan(s): </b> means the pricing plan(s) and the
                    functionality and services associated therewith (as detailed
                    on the Websites) for which You subscribe with respect to any
                    User.{" "}
                  </li>
                  <li>
                    <b>Software: </b> means software provided by Us (either by
                    download or access through the internet) that allows You to
                    use any functionality in connection with the Service(s).
                  </li>
                  <li>
                    <b>Subscription Term: </b> means the period during which You
                    have agreed to subscribe to the Service(s).{" "}
                  </li>
                  <li>
                    <b>SLA:</b> means service level agreement{" "}
                  </li>
                  <li>
                    <b>Websites: </b> means the websites for various Service(s)
                    and other websites that We operate.{" "}
                  </li>
                  <li>
                    The headings of each section in these Terms are only for the
                    purpose of organizing the various provisions under these
                    Terms in an orderly manner and shall not be used by either
                    Party to interpret the provisions contained herein in any
                    manner. Further, it is specifically agreed to by the Parties
                    that the headings shall have no legal or contractual value.{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>2. REGISTRATION </h3>
              <div className="alpha_letters">
                <ol type="a">
                  <li>
                    Registration is mandatory for accessing any or all Services.{" "}
                  </li>
                  <li>
                    Users shall register themselves by only providing their
                    Corporate Email address.{" "}
                  </li>
                  <li>
                    You must be 18 years or older to access or use the Websites
                    and the Service(s){" "}
                  </li>
                  <li>
                    By registering, accessing or using the Service(s) or
                    Websites, or authorizing or permitting any User or End-User
                    to access or use the Service(s) or Websites, You agree to be
                    bound by our Terms. If You are entering into these Terms on
                    behalf of a company, organization or another legal entity
                    (an “Entity”), You are agreeing to these Terms for that
                    Entity and representing to Us that You have the authority to
                    bind such Entity and its Affiliates to these Terms, in which
                    case the terms, “You", “Your” or related capitalized terms
                    used herein shall refer to such entity and its Affiliates.
                    If You do not have such authority, or if You do not agree
                    with these Terms, You must not accept these Terms and may
                    not access or use the Service(s) or Websites.{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>3. YOUR RIGHTS </h3>
              <div className="alpha_letters">
                <ol type="a">
                  <li>
                    These Terms are applicable during Your free trial and during
                    Your subscription to the Service(s) through a Service Plan
                    of Your choice.{" "}
                  </li>
                  <li>
                    <b>Using Our Service(s): </b> Subject to Your compliance
                    with the Terms and solely during the Subscription Term, You
                    have the limited, non-exclusive, and revocable right to
                    access and use the Service(s) for Your internal business
                    purposes. You shall be responsible for use of the Service(s)
                    through Your Account by any third parties. You may subscribe
                    to one or more of the Service(s). They may be subject to
                    separate and distinct Service Plans.{" "}
                  </li>
                  <li>
                    <b>Using our APIs: </b> Where applicable, our APIs must be
                    used according to the API Policies We implement in this
                    regard.{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>4. COMMUNICATION </h3>
              <div className="alpha_letters">
                <p className="alpha_p">
                  Apart from the communications specified in Our Privacy Notice,
                  We may contact You directly via e-mail to notify You if{" "}
                </p>
                <ol type="a">
                  <li>You are in violation of these Terms. </li>
                  <li>
                    A specific activity or purpose is prohibited with respect to
                    the Service(s) so that You immediately cease use of the
                    Service(s) for such prohibited activity or purpose or{" "}
                  </li>
                  <li>
                    You maintain an exceptionally high number of Users, an
                    unusually high monthly conversation and or automation
                    transaction usage per Service Plan, or other excessive
                    stress on the Service(s).{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>5. YOUR RESPONSIBILITIES </h3>
              <div className="alpha_letters count">
                <p>
                  <span>5.1 </span> <b>Your Account:</b> Subject to any
                  limitation available under the Service Plan to which You
                  subscribed, access and use of the Service(s) is restricted to
                  the specified number of individual Users permitted under Your
                  subscription to the Service(s). Each User shall be identified
                  using unique login information such as usernames and passwords
                  (“User Login”) and such User Login shall be used only by one
                  individual. If You are a managed service provider and You wish
                  to use the same User Login across Accounts that You manage for
                  Your clients, You acknowledge that it is Your sole
                  responsibility to obtain necessary consents from such clients.
                  Without prejudice to Our obligations for these Terms, You are
                  solely responsible for the confidentiality of Service Data and
                  User Login at Your end. You should, therefore, not share Your
                  User Login with any third parties. In any event, unless You
                  notify Us of any unauthorized use or suspicious activity in
                  Your Account, You are responsible for all activities that
                  occur under Your Account. Group Companies will not be liable
                  for any damage or loss that may result from Your failure to
                  protect Your login information, including Your password.
                  Without limiting the foregoing, You are solely responsible for
                  ensuring that Your use of the Service(s) to store and transmit
                  Service Data is compliant with all applicable laws and
                  regulations. You also maintain all responsibility for
                  determining whether the Service(s) or the information
                  generated thereby is accurate or sufficient for Your purposes.{" "}
                </p>
                <p className="alpha_p">
                  <span>5.2 </span> <b> Your use of the Service(s):</b> You
                  agree not to{" "}
                </p>
                <div className="alpha_letters">
                  <ol type="a">
                    <li>
                      License, sublicense, sell, resell, rent, lease, transfer,
                      assign, distribute, time share or otherwise commercially
                      exploit or make the Service(s) available to any third
                      party, other than Users and End-Users in furtherance of
                      Your internal business purposes as expressly permitted by
                      these Terms.{" "}
                    </li>
                    <li>
                      Use the Service(s) to Process data on behalf of any third
                      party other than Your Users and End-Users.{" "}
                    </li>
                    <li>
                      Modify, adapt, or hack the Service(s) or otherwise attempt
                      to gain or gain unauthorized access to the Service(s) or
                      related systems or networks.{" "}
                    </li>
                    <li>
                      Falsely imply any sponsorship or association with Us.{" "}
                    </li>
                    <li>
                      Use the Service(s) in any unlawful manner, including but
                      not limited to violation of any person’s privacy rights.{" "}
                    </li>
                    <li>
                      Use the Service(s) to send unsolicited communications junk
                      mail, spam, pyramid schemes or other forms of duplicative
                      or unsolicited messages.{" "}
                    </li>
                    <li>
                      Use the Service(s) to store or transmit any content that
                      infringes upon any person’s intellectual property rights.{" "}
                    </li>
                    <li>
                      Use the Service(s) in any manner that interferes with or
                      disrupts the integrity or performance of the Service(s)
                      and its components.{" "}
                    </li>
                    <li>
                      Attempt to decipher, decompile, reverse engineer,
                      disassemble, reproduce, or copy or otherwise access or
                      discover the source code or underlying program of any
                      Software making up the Service(s).{" "}
                    </li>
                    <li>
                      Use the Service(s) to knowingly post, transmit, upload,
                      link to, send or store any content that is unlawful,
                      racist, hateful, abusive, libelous, obscene, or
                      discriminatory.{" "}
                    </li>
                    <li>
                      Use the Service(s) to store or transmit any “protected
                      health information” as that term is defined in 45 C.F.R.
                      160.103 unless expressly agreed to otherwise in writing by
                      Us.{" "}
                    </li>
                    <li>
                      Use the Service(s) to knowingly post, transmit, upload,
                      link to, send or store any viruses, malware, Trojan
                      horses, time bombs, or any other similar harmful software{" "}
                      <b>(“Malicious Software”)</b>.{" "}
                    </li>
                    <li>
                      Establish a link to Our Websites in such a way as to
                      suggest any form of association, approval or endorsement
                      on Our part where none exists.{" "}
                    </li>
                    <li>
                      Use the Service(s) for the purposes of cookie tracking, ad
                      exchanges, ad networks, data brokerages, or sending
                      electronic communications (including e-mail) in violation
                      of applicable law.{" "}
                    </li>
                    <li>
                      Use of the Service(s) for any purpose prohibited by
                      applicable export laws and regulations, including without
                      limitation, nuclear, chemical, or biological weapons
                      proliferation, or development of missile technology.{" "}
                    </li>
                    <li>
                      Try to use or use the Service(s) in violation of these
                      Terms.{" "}
                    </li>
                  </ol>
                </div>
                <p>
                  You shall be responsible for any loss of data or attempted or
                  actual access or use of the Service(s) through Your Account in
                  violation of these Terms.{" "}
                </p>
                <p>
                  If We inform You that a specified activity or purpose is
                  prohibited with respect to the Service(s), You will ensure
                  that You immediately cease use of the Service(s) for such
                  prohibited activity or purpose.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>6. SERVICE ACCESS </h3>
              <p>
                You may not be able to access or use the Service(s) (a) during
                planned downtime for upgrades and maintenance to the Service(s)
                (of which We will use commercially reasonable efforts to notify
                You in advance through Our Service(s)) (“Planned Downtime”), or
                (b) during any unavailability caused by circumstances beyond Our
                reasonable control, such as, but not limited to, acts of God,
                acts of government, acts of terror or civil unrest, technical
                failures beyond Our reasonable control (including, without
                limitation, inability to access the internet), or acts
                undertaken by third parties, including without limitation,
                distributed denial of service attacks.{" "}
              </p>
              <p>
                We will use commercially reasonable efforts to schedule Planned
                Downtime for weekends (Pacific Time zone) and other off-peak
                hours.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>7. SUSPENSION OF YOUR ACCESS AND ACTIVITY </h3>
              <p>
                We shall not be liable to You or any other third party for
                suspension or termination of Your Account, or access to and use
                the Service(s) if such suspension or termination is in
                accordance with these Terms.{" "}
              </p>
              <p>
                <b>
                  <ins>Free trial Customers:</ins>
                </b>{" "}
                If You are on a free trial for any of Our Service(s), Your
                Account may be suspended or terminated in the following manner:{" "}
              </p>
              <p>
                We may suspend Your access to and use of Your Account or the
                Service(s) if You are in violation of the Terms. We will notify
                You of Your activities that violate these Terms and, at Our sole
                discretion, provide You with a period of fifteen (15) days to
                cease such activities. If You do not cease such activities
                within said Period or if We believe that Your breach of these
                Terms, Your Account shall be terminated and all associated
                Service Data shall be deleted immediately and permanently.{" "}
              </p>
              <p>
                You may terminate Your Account at any time on or before the
                expiry of Your free trial. In such cases, all associated Service
                Data shall be deleted immediately and permanently.{" "}
              </p>
              <p>
                Where you do not terminate Your Account or renew Your Account on
                or before the expiry of Your free trial, We may suspend Your
                Account. We shall retain any associated Service Data for a
                period of 2 months beyond which Your Account shall be terminated
                and all associated Service shall be deleted immediately and
                permanently.{" "}
              </p>
              <p>
                <b>
                  <ins>Customers on a Service Plan:</ins>
                </b>{" "}
                If You are on a Service Plan for any of Our Service(s), Your
                Account may be suspended or terminated in the following manner:{" "}
              </p>
              <p>
                In addition to suspension for late payment or non-payment of
                Subscription Charges, We may suspend Your access to and use of
                Your Account or the Service(s) if You are in violation of the
                Terms. We will notify You of Your activities that violate these
                Terms and, at Our sole discretion, provide You with a period of
                fifteen (15) days to cure or cease such activities. If You do
                not cure or cease such activities within said Cure Period or if
                We believe that Your breach of these Terms cannot be cured, Your
                Account shall be terminated. Any associated Service Data shall
                be retained for a period of 2 months from the date of
                termination of Your Account beyond which it shall be deleted
                during the normal course of operation.{" "}
              </p>
              <p>
                You may elect to terminate Your Account at any time from within
                Our Service(s) if You pay for Your Account through credit card.
                If payment for Your Account is made through other accepted
                payment methods as specified in the Form, You may request to
                terminate Your Account by writing to {" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:support@workativ.com"
                >
                  <b> support@workativ.com</b>
                </a>
                . Any associated Service Data shall be retained for a period of
                2 months from the date of termination of Your Account beyond
                which it shall be deleted during the normal course of operation.{" "}
              </p>
              <p>
                We may suspend Your Account upon expiry or non-renewal of Your
                Subscription Term. We shall retain any associated Service Data
                for a period of 2 months beyond which Your Account shall be
                terminated and all associated Service shall be deleted
                immediately and permanently.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>8. BILLING, PLAN MODIFICATION AND PAYMENT </h3>
              <div className="numders_list_p">
                <p>
                  <span>8.1 </span>
                  <b>Subscription Charges:</b> Unless otherwise specified in the
                  Supplementary terms if any, except during Your free trial, all
                  charges associated with Your Account{" "}
                  <b>(“Subscription Charges”)</b> are due in full and payable in
                  advance, when You subscribe to the Service(s). Unless
                  specified otherwise in a Form, the Subscription Charges are
                  based on the Service Plans You choose and are payable in full
                  until You terminate Your Account. You will receive a receipt
                  upon each receipt of payment by Us. You may also obtain a
                  payment receipt from within the Service(s).{" "}
                </p>
                <p>
                  <span>8.2 </span>
                  <b>Payment methods:</b> You may pay the Subscription Charges
                  through Your credit card, or other accepted payment methods as
                  specified in a Form. For credit card payments, Your payment is
                  due immediately upon Your receipt of Our invoice. You hereby
                  authorize Us or Our authorized agents, as applicable, to bill
                  Your credit card upon Your subscription to the Service(s) (and
                  any renewal thereof). For payments through other accepted
                  methods, Your payment is due within seven <b>(7) days</b> of
                  Our invoice date unless otherwise stated in a Form.{" "}
                </p>
                <p>
                  <span>8.3 </span>
                  <b>Renewal:</b> Your subscription to the Service(s) will renew
                  automatically for a Subscription Term equivalent in length to
                  the then expiring Subscription Term. Unless otherwise provided
                  for in any Form, the Subscription Charges applicable to Your
                  subscription to the Service(s) for any such subsequent
                  Subscription Term shall be Our standard Subscription Charges
                  for the Service Plan to which You have subscribed as of the
                  time such subsequent Subscription Term commences. You
                  acknowledge and agree that, unless You terminate Your Account,
                  Your credit card will be charged automatically for the
                  applicable Subscription Charges. <br />
                  <br /> We may use a third-party service provider to manage
                  credit card and other payment processing, provided, that such
                  service provider is not permitted to store, retain or use Your
                  payment account information except to process Your credit card
                  and other payment information for Us. You must notify Us of
                  any change in Your credit card or other payment account
                  information, either by updating Your Account or by e-mailing
                  us at{" "}
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:support@workativ.com"
                  >
                    <b>support@workativ.com</b>
                  </a>
                  .{" "}
                </p>
                <p>
                  <span>8.4 </span>
                  <b>Refunds:</b> Unless otherwise specified in these Terms or a
                  Form or a Service Plan, all Subscription Charges are
                  non-refundable. No refunds shall be issued for partial use or
                  non-use of the Service(s) by You provided however You shall be
                  eligible for a pro-rated refund of the Subscription Charges
                  for the remainder of the Subscription Term if You terminate
                  Your Account as a result of a material breach of these Terms
                  by Us.{" "}
                </p>
                <p className="alpha_p">
                  <span>8.5 </span>
                  <b>Late Payments/Non-payment of Subscription Charges:</b> We
                  will notify You if We do not receive payment towards the
                  Subscription Charges within the due date for Your Account. For
                  payments made through credit cards, We must receive payments
                  due within a maximum of <b>five (5) days</b> from the date of
                  Our notice and for payments through other accepted methods, We
                  must receive payments within a maximum of{" "}
                  <b>fifteen (15) days</b> from the date of Our notice. If We do
                  not receive a payment within the foregoing time period, in
                  addition to Our right to other remedies available under law,
                  We may
                </p>
                <div className="alpha_letters">
                  <ol type="i">
                    <li>
                      {" "}
                      charge an interest for late payment @ 1.5% per month
                      and/or;{" "}
                    </li>
                    <li>
                      {" "}
                      suspend Your access to and use of the Service(s) until We
                      receive Your payment towards the Subscription Charges as
                      specified herein and/or;{" "}
                    </li>
                    <li>
                      {" "}
                      terminate Your Account in accordance with these terms of
                      User.{" "}
                    </li>
                  </ol>
                </div>
                <p />
                <p>
                  <span>8.6 </span>
                  <b>Upgrades and Downgrades:</b> You may upgrade within a
                  Service Plan or between two Service Plans. The new
                  Subscription Charges become immediately applicable. Upon
                  upgrade, the new Subscription Charges for the subsisting month
                  would be charged on a pro-rated basis and Your credit card
                  will be charged automatically. Subsequent months will be
                  charged in full according to the new Subscription Charges.
                </p>
                <p>
                  <span>8.7 </span>
                  <b>Applicable Taxes:</b> Unless otherwise stated, the
                  Subscription Charges do not include any taxes, levies, duties
                  or similar governmental assessments, including value-added,
                  sales, use or withholding taxes assessable by any local,
                  state, provincial or foreign jurisdiction (collectively
                  “Taxes”). You are responsible for paying the Taxes that would
                  be levied against You by government authorities. We will
                  invoice You for such Taxes if We believe We have a legal
                  obligation to do so and You agree to pay such Taxes if so
                  invoiced.{" "}
                </p>
                <p>
                  <span>8.8 </span>
                  <b>User Benefits:</b> We may, at Our sole discretion, offer
                  You certain benefits such as discounts on Subscription
                  Charges, extension in Subscription Term for no extra payments
                  from You, with regard to the Service(s). These benefits are
                  specific to Your Account and the Service(s) identified while
                  offering these benefits. They are not transferrable. The
                  benefits may have an expiry date. If they do not have an
                  expiry date, they will expire upon completion of twelve (12)
                  months from their date of the offer.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>9. THIRD-PARTY SERVICES </h3>
              <p>
                Our Services integrates with Third-party apps (“Third Party
                Services”), and such integrations are made available to You
                through Our platform. By enabling and or using the Third Party
                Services, You understand and agree that We do not provide any
                warranties whatsoever for Third Party Services and We are not
                liable for any damage or loss caused or alleged to be caused by
                or in connection with Your enablement, access or use of any such
                Third Party Services, or Your reliance on the privacy practices,
                data security processes or other policies of such Third Party
                Services. Please be mindful of any Data that will be shared with
                the Third Party apps and the purposes for which the third party
                apps requires access. We will not be responsible for any use,
                disclosure, modification, loss or deletion of Data that is
                transmitted to, or accessed by, a Third Party app. You
                understand that We are not responsible for providing technical
                support for Third Party Services and that We are not responsible
                for the data hosting and data transfer practices followed by
                such Third Party Services. To this extent, You shall address any
                comments, queries, complaints or feedback about such Third Party
                Services to their respective app owners.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>10. POST TERMINATION COVENANTS </h3>
              <p>
                <span>10.1 </span>
                <b>Data Export:</b> In any event, following the termination of
                Your Account either by You or Us, unless otherwise specified
                elsewhere herein or in the Supplemental Terms, Service Data will
                be retained or deleted in accordance with Sections 8 as
                applicable to You. Where the Service Data is retained as
                described herein, You may contact us within such a data
                retention period to export Your Service Data. Service Data
                cannot be recovered once it is deleted. Further, when Service
                Data is migrated from one data centre to another upon Your
                request, We shall delete Service Data from the original data
                centre after <b>14 (fourteen) days</b> from such migration.{" "}
              </p>
              <p>
                <span>10.2 </span>
                <b>Charges:</b> If Your Account is terminated under these Terms,
                in addition to other amounts You may owe Us, You must
                immediately pay any then unpaid Subscription Charges associated
                with the remainder of such Subscription Term unless waived by Us
                in writing. This amount will not be payable by You, or You may
                be eligible for a pro-rated refund of the Subscription Charges,
                as the case may be, where You terminate Your subscription to the
                Service(s) or terminate Your Account as a result of a material
                breach of these Terms by Us, provided that You provide notice of
                such breach to Us and afford Us not less than{" "}
                <b>thirty (30) days</b> to reasonably cure such breach.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>11. INDEMNITY </h3>
              <div className="alpha_letters">
                <p className="alpha_p">
                  <span>11.1 </span>
                  <b>Indemnification by Us:</b>
                  Subject to Your compliance with these Terms, We will indemnify
                  and hold You harmless, from and against any claim brought
                  against You by a third party alleging that the Service(s) You
                  subscribed to infringes or misappropriates such third party’s
                  valid patent, copyright, or trademark (an <b>“IP Claim”</b>).
                  We shall, at Our expense, defend such IP Claim and pay damages
                  finally awarded against You in connection therewith, including
                  the reasonable fees and expenses of the attorneys, provided
                  that
                </p>
                <ol type="a">
                  <li>
                    You promptly notify Us of the threat or notice of such IP
                    Claim;{" "}
                  </li>
                  <li>
                    We have or will have the sole and exclusive control and
                    authority to select defense attorneys, defend and/or settle
                    any such IP Claim; and{" "}
                  </li>
                  <li>
                    You fully cooperate with Us in connection therewith. We will
                    have no liability or obligation with respect to any IP Claim
                    if such claim is caused in whole or in part by (i)
                    compliance with designs, data, instructions or
                    specifications provided by You; (ii) modification of the
                    Service(s) by anyone other than Us; or (iii) the
                    combination, operation or use of the Service(s) with other
                    hardware or software where the Service(s) would not by
                    themselves be infringing.{" "}
                  </li>
                </ol>
              </div>
              <div className="alpha_letters">
                <p className="alpha_p">
                  You are prohibited from uploading content that contains
                  viruses, Trojan horses, time bombs, corrupted files or any
                  other equivalent software or programs that may damage the
                  operation of the Site (or any part of it) or another's
                  (including Workativ’s) computer or property;
                </p>
                <ol type="a">
                  <li>
                    procure for You the right to continue using the Service(s)
                    as set forth hereunder;{" "}
                  </li>
                  <li>
                    replace or modify the Service(s) to make it non-infringing;
                    or{" "}
                  </li>
                  <li>
                    if options (a) or (b) are not commercially and reasonably
                    practicable as determined by Us, terminate Your subscription
                    to the Service(s) and repay You, on a pro-rated basis, any
                    Subscription Charges You have previously paid Us for the
                    corresponding unused portion.{" "}
                  </li>
                </ol>
                <p>
                  Our sole, exclusive and entire liability to You and constitute
                  Your sole remedy with respect to an IP Claim brought by reason
                  of access to or use of the Service(s) by You.{" "}
                </p>
              </div>
              <p>
                <span>11.2 </span>You will indemnify and hold Group Companies
                harmless against any claim brought by a third party against Us,
                and their respective employees, officers, directors and agents
                arising from or related to use of the Service(s) by You in
                breach of these Terms or matters which You have expressly agreed
                to be responsible pursuant to these Terms; provided that We
                promptly notify You of the threat or notice of such a claim.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3 className="text-uppercase">12. Data Privacy and Security </h3>
              <p>
                <b>Security of Service Data:</b> We use appropriate technical
                and organizational measures to protect the Service Data that we
                Process. The measures we use are designed to provide a level of
                security appropriate to the risk of Processing your Service
                Data.
              </p>
              <div className="alpha_letters">
                <p className="alpha_p">
                  You understand that We and our Group Companies shall Process
                  Service Data in accordance with Applicable Data Protection
                  Laws and the Data Processing Addendum which are incorporated
                  into these Terms by reference and in accordance with Our
                  Privacy Notice. You acknowledge and agree that Group Companies
                  may also access or disclose information about You, Your
                  Account, Users or End-Users, including Service Data, in order
                  to
                </p>
                <ol type="a">
                  <li>
                    Comply with the law or respond to lawful requests or legal
                    process;{" "}
                  </li>
                  <li>
                    Protect Group Companies’ or Our customers’ or partners’
                    rights or property, including enforcement of these Terms or
                    other policies associated with the Service(s);
                  </li>
                  <li>
                    Act on a good faith belief that such disclosure is necessary
                    to protect personal safety or avoid violation of applicable
                    law or regulation. Further, at Our sole discretion, any
                    suspected fraudulent, abusive, or illegal activity by You
                    may be referred to law enforcement authorities.{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>13. WORKATIV ASSISTANT PRODUCT SUPPORT SLAs </h3>

              <p>
                <p className="p-0 mb-1">
                  <span>13.1 </span>
                  <b>Severity Level - Severity 1</b>
                </p>
                <p className="p-0 mb-2">
                  <b>Description of Problem:</b> A critical problem that
                  involves Availability or fundamental functionality of the
                  Application that precludes productive use of the Application,
                  and that is having, or is likely to have, an immediate and
                  material impact on a critical business activity of Client.
                </p>
                <p className="p-0 mb-4">
                  <b>Workativ Target Response Times:</b> Respond within four (4)
                  hours of issue being logged; it is recommended that all
                  severity 1 issues be logged via a dedicated email address to
                  Product Support{" "}
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:support@workativ.com"
                  >
                    <b>(support@workativ.com).</b>
                  </a>{" "}
                  Problem worked 24 x 7 x 365 until resolved or work around
                  provided.
                </p>
              </p>
              <p>
                <p className="p-0 mb-1">
                  <span>13.2 </span>
                  <b>Severity 2</b>
                </p>
                <p className="p-0 mb-2">
                  <b>Description of Problem:</b> A significant problem that
                  involves functionality of the Application or degraded
                  Availability, but that does not preclude productive use of the
                  Application and is not having and is not likely to have an
                  immediate and material impact on a critical business activity
                  of Client.
                </p>
                <p className="p-0 mb-4">
                  <b>Workativ Target Response Times:</b> Respond within eight
                  (8) hours of issue being logged with Workativ Product Support&nbsp;
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:support@workativ.com"
                  >
                  <b>(support@workativ.com).</b>
                  </a>{" "}
                  Problem worked 24 x 7 x 365 until resolved or work around
                  provided.
                </p>
              </p>
              <p>
                <p className="p-0 mb-1">
                  <span>13.3 </span>
                  <b>Severity 3</b>
                </p>
                <p className="p-0 mb-2">
                  <b>Description of Problem:</b> An inconvenient problem with
                  the Application that inhibits a feature of the Application but
                  does not preclude productive use of the Application.
                </p>
                <p className="p-0 mb-4">
                  <b>Workativ Target Response Times:</b> Respond within one (1)
                  Business Day of issue being logged with Workativ Product
                  Support{" "}
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:support@workativ.com"
                  >
                    <b>(support@workativ.com).</b>
                  </a>{" "}
                  Problem worked primarily during Product Support Hours. 
                </p>
              </p>
              <p>
                <p className="p-0 mb-1">
                  <span>13.4 </span>
                  <b>Severity 4</b>
                </p>
                <p className="p-0 mb-2">
                  <b>Description of Problem:</b> General questions related to
                  the use of the Application, a “how to” question; an error that
                  is minor or cosmetic in nature; or a request to be considered
                  for future enhancements.
                </p>
                <p className="p-0 mb-2">
                  <b>Workativ Target Response Times:</b> Respond within two (2)
                  Business Day of issue being logged with Workativ Product
                  Support{" "}
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:support@workativ.com"
                  >
                    <b>(support@workativ.com).</b>
                  </a>{" "}
                  Problem worked primarily during Product Support Hours. 
                </p>
              </p>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>14. LIMITATION OF LIABILITY </h3>
              <div className="alpha_letters">
                <p className="alpha_p">
                  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  WILL WE, OUR AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES,
                  AGENTS, SUPPLIERS OR LICENSORS BE LIABLE TO ANY PERSON FOR ANY
                  INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COVER OR
                  CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES
                  FOR LOST PROFITS, LOST REVENUE, LOST SALES, LOST GOODWILL,
                  LOSS OF USE OR LOST CONTENT, IMPACT ON BUSINESS, BUSINESS
                  INTERRUPTION, LOSS OF ANTICIPATED SAVINGS, LOSS OF BUSINESS
                  OPPORTUNITY) HOWEVER CAUSED, UNDER ANY THEORY OF LIABILITY,
                  INCLUDING, WITHOUT LIMITATION, CONTRACT, TORT, WARRANTY,
                  BREACH OF STATUTORY DUTY, NEGLIGENCE OR OTHERWISE, EVEN IF WE
                  HAVE BEEN ADVISED AS TO THE POSSIBILITY OF SUCH DAMAGES OR
                  COULD HAVE FORESEEN SUCH DAMAGES. TO THE MAXIMUM EXTENT
                  PERMITTED BY APPLICABLE LAW, OUR AGGREGATE LIABILITY AND THAT
                  OF OUR AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS AND
                  LICENSORS, RELATING TO THE SERVICE(S), WILL BE LIMITED TO AN
                  AMOUNT EQUAL TO THE LOWER OF{" "}
                </p>
                <ol type="a">
                  <li>
                    TWELVE MONTHS OF THE SUBSCRIPTION CHARGES FOR THE SERVICE(S)
                    TO WHICH THE CLAIM RELATES; OR{" "}
                  </li>
                  <li>
                    THE SUBSCRIPTION CHARGES PAID BY YOU, FOR THE SERVICE(S) TO
                    WHICH THE CLAIM RELATES PRIOR TO THE FIRST EVENT OR
                    OCCURRENCE GIVING RISE TO SUCH LIABILITY. YOU ACKNOWLEDGE
                    AND AGREE THAT TO PROVIDE YOU WITH THE RIGHTS TO ACCESS AND
                    USE THE SERVICE(S) IN ACCORDANCE WITH SECTION 1, WE HAVE
                    LIMITED OUR POTENTIAL LIABILITY AND ALLOCATED RISKS BASED ON
                    THE SUBSCRIPTION CHARGES, WHICH WOULD HAVE BEEN
                    SUBSTANTIALLY HIGHER IF WE WERE TO ASSUME ANY FURTHER
                    LIABILITY OTHER THAN AS SET FORTH HEREIN.{" "}
                  </li>
                </ol>
              </div>
              <p />
              <p>
                IN JURISDICTIONS WHICH DO NOT PERMIT THE EXCLUSION OF IMPLIED
                WARRANTIES OR LIMITATION OF LIABILITY FOR INCIDENTAL OR
                CONSEQUENTIAL DAMAGES, OUR LIABILITY WILL BE LIMITED TO THE
                GREATEST EXTENT PERMITTED BY LAW.{" "}
              </p>
              <p>
                THE LIMITATIONS AND EXCLUSIONS ALSO APPLY IF THIS REMEDY DOES
                NOT FULLY COMPENSATE YOU FOR ANY LOSSES OR FAILS OF ITS
                ESSENTIAL PURPOSE.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>15. INTELLECTUAL PROPERTY RIGHTS (IPR) </h3>
              <p>
                Except for the rights granted to You, all rights, title and
                interest in and to all Our patents, inventions, copyrights,
                trademarks, domain names, trade secrets, know-how and any other
                intellectual property and/or proprietary rights in or related to
                the Service(s), including the Websites, and any part of it
                (collectively, <b>“Intellectual Property Rights”</b>) shall
                belong to and remain exclusively with Us. We are the owner or
                the licensee of all Intellectual Property Rights in Our
                Websites, and the content or material published on it. Those
                works are protected by copyright laws and treaties around the
                world. You must not use any part of the content on Our Websites
                for commercial purposes without obtaining a license to do so
                from Us or Our licensors. Further, We claim no intellectual
                property rights over the content You upload or provide to the
                Service(s).
              </p>
              <p>
                <span>15.1 </span>
                <b>Grant of License to Us:</b>
                We shall have a royalty-free, worldwide, transferable,
                sub-licensable, irrevocable and perpetual license to incorporate
                into the Service(s) or Websites or otherwise use any
                suggestions, enhancement requests, recommendations or other
                feedback We receive from You.
              </p>
              <p>
                <span>15.2 </span>
                <b>Grant of License to You:</b>
                Our product and service names and logos used or displayed on the
                Service(s) or Websites are Our registered or unregistered
                trademarks (collectively, “Marks”), and You may only use such
                Marks to identify You as a user of the Service(s) You have
                subscribed to.
              </p>
              <p>
                <span>15.3 </span>
                <b>Reservation of Rights:</b>
                All rights not expressly provided to You herein are reserved.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>16. CONFIDENTIALITY </h3>
              <p>
                If You choose, or You are provided with, a user identification
                code, password or any other piece of information as part of Our
                security procedures, You must treat such information as
                confidential. You must not disclose it to any third party. We
                have the right to disable any user identification code or
                password, whether chosen by You or allocated by Us, at any time,
                if in Our reasonable opinion, You have failed to comply with any
                of the provisions of these Terms.
              </p>
              <p>
                <b>Confidentiality obligations:</b> Each of us will protect the
                other’s Confidential Information from unauthorized use, access
                or disclosure in the same manner as each of us protects our own
                Confidential Information, and in any event, no less than
                reasonable care. Except as otherwise expressly permitted
                pursuant to these Terms, each of us may use the other’s
                Confidential Information solely to exercise our respective
                rights and perform our respective obligations under these Terms
                and shall disclose such Confidential Information solely to those
                of our respective employees, representatives and agents who have
                a need to know such Confidential Information for such purposes
                and who are bound to maintain the confidentiality of, and not
                misuse such Confidential Information. The provisions of this
                subsection shall supersede any non-disclosure agreement by and
                between You and Us entered prior to these Terms that would
                purport to address the confidentiality of Service Data and such
                agreement shall have no further force or effect with respect to
                Service Data.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>17. DISCLAIMER OF WARRANTIES AND LIABILITIES </h3>
              <p>
                THE WEBSITES AND THE SERVICE(S), INCLUDING ALL SERVER AND
                NETWORK COMPONENTS, ARE PROVIDED ON AN “AS IS” AND “AS
                AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND TO THE
                FULLEST EXTENT PERMITTED BY APPLICABLE LAW. WE EXPRESSLY
                DISCLAIM ANY AND ALL CONDITIONS, REPRESENTATIONS, WARRANTIES OR
                OTHER TERMS, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE,
                FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT.
              </p>
              <p>
                YOU ACKNOWLEDGE THAT WE DO NOT WARRANT THAT THE SERVICE(S) OR
                WEBSITES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE AND
                YOU FURTHER ACKNOWLEDGE THAT WE DO NOT WARRANT THAT THE ACCESS
                TO THE SERVICE(S), WHICH IS PROVIDED OVER INTERNET AND VARIOUS
                TELECOMMUNICATIONS NETWORKS, ALL OF WHICH ARE BEYOND OUR
                CONTROL, WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR
                FREE FROM VIRUSES OR OTHER MALICIOUS SOFTWARE. THE CONTENT ON
                OUR WEBSITES IS PROVIDED FOR GENERAL INFORMATION ONLY. IT IS NOT
                INTENDED TO AMOUNT TO ADVICE ON WHICH YOU SHOULD RELY. YOU MUST
                OBTAIN PROFESSIONAL OR SPECIALIST ADVICE BEFORE TAKING OR
                REFRAINING FROM, ANY ACTION ON THE BASIS OF THE CONTENT ON OUR
                WEBSITES. NO INFORMATION OR ADVICE OBTAINED BY YOU FROM US OR
                THROUGH THE SERVICE(S) OR WEBSITES SHALL CREATE ANY WARRANTY NOT
                EXPRESSLY STATED IN THESE TERMS.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>
                18. EXPORT COMPLIANCE AND USE RESTRICTIONS, FEDERAL GOVERNMENT
                END USE PROVISIONS{" "}
              </h3>
              <div className="alpha_letters">
                <p className="alpha_p">
                  The Service(s) and other Software or components of the
                  Service(s) which We may provide or make available to You or
                  Users may be subject to U.S. (or other territories) export
                  control and economic sanctions laws. You agree to comply with
                  all such laws and regulations as they relate to access to and
                  use of the Service(s), Software and such other components by
                  You and Users. You shall not access or use the Service(s) if
                  You are located in any jurisdiction in which the provision of
                  the Service(s), Software or other components is prohibited
                  under the U.S. or other applicable laws or regulations (a{" "}
                  <b>“Prohibited Jurisdiction”</b>) and You shall not provide
                  access to the Service(s) to any government, entity or
                  individual located in any Prohibited Jurisdiction. You
                  represent, warrant and covenant that{" "}
                </p>
                <ol type="i">
                  <li>
                    You are not named on any U.S. government (or other
                    government) list of persons or entities prohibited from
                    receiving U.S. exports, or transacting with any U.S. person,{" "}
                  </li>
                  <li>
                    You are not a national of, or a company registered in any
                    Prohibited Jurisdiction,{" "}
                  </li>
                  <li>
                    You shall not permit Users to access or use the Service(s)
                    in violation of any the U.S. or other applicable export
                    embargoes, prohibitions or restrictions, and{" "}
                  </li>
                  <li>
                    You shall comply with all applicable laws regarding the
                    transmission of technical data exported from the United
                    States and the country in which You and Your Users are
                    located.{" "}
                  </li>
                </ol>
              </div>
              <p>
                If You are a U.S. federal government department or agency or
                contracting on behalf of such department or agency, this
                Service(s) is a “Commercial Item” as that term is defined at 48
                C.F.R. §2.101, consisting of “Commercial Computer Software” and
                “Commercial Computer Software Documentation”, as those terms are
                used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202. Consistent
                with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through
                227.7202-4, as applicable, the Service(s) is licensed to You
                with only those rights as provided under the terms and
                conditions of these Terms.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>19. DISPUTE RESOLUTION AND JURISDICTION </h3>
              <div className="alpha_letters">
                <p className="alpha_p">
                  It is expressly agreed to by the Parties hereto that the
                  formation, interpretation, and performance of these Terms and
                  any disputes arising therefrom will be resolved through a
                  two-step Alternate Dispute Resolution <b>(“ADR”)</b>{" "}
                  mechanism. It is further agreed to by the Parties that the
                  contents of this Section shall survive even after the
                  termination or expiry of the Terms and/or Policy.{" "}
                </p>
                <ol type="a">
                  <li>
                    <b>Mediation:</b> In case of any dispute between the
                    parties, the Parties will attempt to resolve the same
                    amicably amongst themselves, to the mutual satisfaction of
                    all parties. In the event that the Parties are unable to
                    reach such an amicable solution within thirty (30) days of
                    one Party communicating the existence of a dispute to any
                    other Party, the dispute will be resolved by arbitration, as
                    detailed hereinbelow;{" "}
                  </li>
                  <li>
                    <b>Arbitration:</b> In the event that the Parties are unable
                    to amicably resolve a dispute by mediation, said dispute
                    will be referred to arbitration by a sole arbitrator to be
                    appointed by us, and the award passed by such sole
                    arbitrator will be valid and binding on all parties. The
                    Parties shall bear their own costs for the proceedings,
                    although the sole arbitrator may, in his/her sole
                    discretion, direct either Party to parties the entire cost
                    of the proceedings. The arbitration shall be conducted in
                    English, and the seat of Arbitration shall be the State of
                    Delaware.{" "}
                  </li>
                </ol>
              </div>
              <p>
                The Parties expressly agree that the Terms, Policy and any other
                agreements entered into between the Parties are governed by the
                laws, rules, and regulations of <b>State of Delaware</b>.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>20. GENERAL PROVISIONS </h3>
              <ol type="a">
                <li>
                  <b>Entire Agreement:</b> These Terms, read with the Policy,
                  form the complete and final contract between the You and Us
                  with respect to the subject matter hereof and supersedes all
                  other communications, representations, and agreements (whether
                  oral, written or otherwise) relating thereto.{" "}
                </li>
                <li>
                  <b>Revision:</b> We may amend these Terms from time to time,
                  in which case the new Terms will supersede prior versions.
                  Please read these Terms of use carefully before You start to
                  use Our Service(s) or Websites, as these will apply to Your
                  use of the Service(s) and Our Websites. Please check these
                  Terms from time to time to take notice of any changes We made,
                  as they will be binding on You. We will notify You not less
                  than <b>ten (10) days</b> prior to the effective date of any
                  amendments to these terms of service and Your continued use of
                  the Service(s) following the effective date of any such
                  amendment may be relied upon by Us as Your acceptance of any
                  such amendment. With respect to amendments only to the
                  Supplemental Terms, We will notify You as aforementioned only
                  if the Supplemental Terms are applicable to You. Our failure
                  to enforce at any time any provision of these Terms does not
                  constitute a waiver of that provision or of any other
                  provision of the Terms.{" "}
                </li>
                <li>
                  <b>Relationship of the Parties:</b>T he parties are
                  independent contractors. These Terms do not create a
                  partnership, franchise, joint venture, agency, fiduciary or
                  employment relationship among the parties.{" "}
                </li>
                <li>
                  <b>Force Majeure:</b> We shall not be liable for damages for
                  any delay or failure to perform its obligations hereunder if
                  such delay or failure is due to cause beyond our control or
                  without its fault or negligence, due to Force Majeure events
                  including but not limited to acts of war, acts of God,
                  earthquake, riot, fire, festive activities sabotage, labour
                  shortage or dispute, internet interruption, technical failure,
                  breakage of sea cable, hacking, piracy, cheating, illegal or
                  unauthorized.{" "}
                </li>
                <li>
                  <b>Survival:</b> Sections 5 (Your Responsibilities), 7
                  (Suspension and Termination), 8 (Billing, Plan Modification
                  and Payments), 11 (Indemnification), 12 (Data Privacy and
                  Security), 13 (Limitation of Liability), 14 (Intellectual
                  Property Rights), 15 (Confidentiality), 16 (Disclaimer of
                  Warranties), 18 (Dispute Resolution and Jurisdiction), and 19
                  (General Provision) shall survive any termination of Our
                  agreement with respect to use of the Service(s) by You.
                  Termination of such agreement shall not limit Your or Our
                  liability for obligations accrued as of or prior to such
                  termination or for any breach of these Terms.{" "}
                </li>
                <li>
                  <b>No Waiver:</b> The failure of either Party at any time to
                  require performance of any provision of these Terms shall in
                  no manner affect such Party's right at a later time to enforce
                  the same. No waiver by either party of any breach of these
                  Terms, whether by conduct or otherwise, in any one or more
                  instances, shall be deemed to be or construed as a further or
                  continuing waiver of any such breach, or a waiver of any other
                  breach of these Terms
                </li>
                <li>
                  <b>Severability:</b> If any provision/clause of these Terms is
                  held to be invalid, illegal or unenforceable by any court or
                  authority of competent jurisdiction, the validity, legality,
                  and enforceability of the remaining provisions/clauses of
                  these Terms shall in no way be affected or impaired thereby,
                  and each such provision/clause of these Terms shall be valid
                  and enforceable to the fullest extent permitted by law. In
                  such a case, these Terms shall be reformed to the minimum
                  extent necessary to correct any invalidity, illegality or
                  unenforceability, while preserving to the maximum extent the
                  original rights, intentions and commercial expectations of the
                  Parties hereto, as expressed herein.{" "}
                </li>
                <li>
                  <b>Anti-Corruption:</b> You agree that You have not received
                  or been offered any illegal or improper bribe, kickback,
                  payment, gift, or thing of value from any of Our employees or
                  agents in connection with these Terms. Reasonable gifts and
                  entertainment provided in the ordinary course of business do
                  not violate the above restriction. If You learn of any
                  violation of the above restriction, You will use reasonable
                  efforts to promptly notify us at{" "}
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:support@workativ.com"
                  >
                    <b>support@workativ.com</b>
                  </a>
                  .
                </li>
                <li>
                  <b>Notices:</b> Any and all communication relating to any
                  dispute or grievance experienced by You may be communicated to
                  us by emailing to{" "}
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:support@workativ.com"
                  >
                    <b>support@workativ.com</b>
                  </a>
                  .{" "}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
